import React, { useEffect } from "react";
import { useAppContext } from "../../Context";
import logo from "../../assets/images/logo.png";
import { useLocalStorage } from "../../hooks";

const tabs = [
  { id: "1", label: "Notifications", href: "/notifications" },
  {
    id: "2",
    label: "Bot Management",
    href: "/bots-management",
  },
  {
    id: "3",
    label: "Bot Activation Requests",
    href: "/bots-activation",
  },
  {
    id: "4",
    label: "Invoice Center",
    href: "/invoice",
  },
  { id: "5", label: "Payout Center", href: "/payout" },
  { id: "6", label: "Data Room", href: "/data-room" },
  { id: "7", label: "Sign out", href: "/sign-out" },
];

function Sidebar({ active }) {
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  return (
    <div className="mx-5 fixed min-h-screen bg-transparent flex flex-col gap-2 mt-6">
      <div className="flex items-center gap-4 opacity-100">
        <img className="w-4" src={logo} alt="logo" />
        <span className="font-semibold text-lg ">Admin Area</span>
      </div>
      {tabs.map((item) => (
        <div key={item.id} className={`py-2 flex flex-col opacity-60 `}>
          <div
            onClick={() => {
              if (item.href === "/sign-out") {
                setAuthToken("");
                window.open("/", "_self");
              } else {
                window.open(item.href, "_self");
              }
            }}
            className="text-lg font-semibold cursor-pointer"
          >
            {item.label}
          </div>
          {item.id == active.id && (
            <div className="border-b-[0.8px] ] mt-2 w-10" />
          )}
        </div>
      ))}
    </div>
  );
}

export default Sidebar;
