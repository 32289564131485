import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import { useLoading, useLocalStorage } from "../../hooks";
import MainWrapper from "../MainWrapper";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";
import { toast } from "react-toastify";
import front_arrow from "../../assets/icons/front-arrow.png";
import { FetchBot, MarginCall, UpdateBot } from "../../API";
import { useLocation } from "react-router-dom";
import ReactSwitch from "react-switch";

function Details() {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken] = useLocalStorage("authToken", "");
  const [bot, setbot] = useState({});
  const [manualProfit, setmanualProfit] = useState(bot.manualProfit || "");
  const [fee, setFee] = useState(bot.fee || "");
  const [checked, setChecked] = useState(bot.marginCall || false);

  const location = useLocation();
  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters by key
  const bot_id = queryParams.get("bot_id");

  const updateBot = async () => {
    if (!bot._id) return;
    startLoading();
    const res = await UpdateBot(
      {
        manualProfit: parseFloat(manualProfit || "0"),
        fee: parseFloat(fee || "0"),
        bot_id: bot._id,
      },
      authToken
    );
    console.log(res);
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const handleMarginCall = async (status) => {
    if (!bot._id) return;
    startLoading();
    const res = await MarginCall(
      {
        bots: [{ _id: bot_id, status }],
      },
      authToken
    );

    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const fetchBot = async () => {
    startLoading();
    const res = await FetchBot(authToken, bot_id);
    console.log(res);
    if (res?.success) {
      setbot(res.bot);
      setChecked(res.bot.marginCall);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  console.log(bot._id);

  useEffect(() => {
    if (!authToken) {
      toast.error("Please sign in to continue", { position: "top-center" });
      setTimeout(() => {
        window.open("/", "_self");
      }, 3000);
    } else {
      fetchBot();
    }
  }, []);

  return (
    <MainWrapper>
      <Sidebar active={{ id: "3", href: "/bots-activation" }} />
      <Header />
      <Wrapper style={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />
        <div
          onClick={() => window.open(`/bots-activation`, "_self")}
          className="flex items-center gap-4 cursor-pointer w-[15%] py-2"
        >
          <img src={front_arrow} className="w-8 rotate-[180deg]" />
          <span className="font-semibold whitespace-nowrap">All Bots</span>
        </div>

        {bot._id ? (
          <div className="flex justify-between gap-8 mt-4">
            <div className="bg-[#151924] py-8 px-6 rounded-xl flex-1 flex flex-col gap-4 text-gray-400">
              <div className="flex justify-between items-center">
                <span>user id</span>
                <span>{bot.user}</span>
              </div>
              <div className="flex justify-between items-center">
                <span>strategy name</span>
                <span>{bot.strategy}</span>
              </div>
              <div className="flex justify-between items-center">
                <span>profit (USD)</span>
                <span>{bot.currentROI}</span>
              </div>
              <div className="flex justify-between items-center">
                <span>profit coin</span>
                <span>{bot.profitCurrency}</span>
              </div>
              <div className="flex justify-between items-center">
                <span>deposit coin</span>
                <span>{bot.depositCurrency}</span>
              </div>
              <div className="flex justify-between items-center">
                <span>bot status</span>
                <span>{bot.status}</span>
              </div>
              <div className="flex justify-between items-center">
                <span>manual profit</span>
                <span>{bot.manualProfit || 0}</span>
              </div>
            </div>
            <div className="bg-[#151924] py-8 px-6 rounded-xl flex-1 flex flex-col gap-12">
              <div className="flex justify-between items-center border-b-[0.5px] py-4">
                <span>Margin call</span>
                <ReactSwitch
                  label={"hh"}
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                    handleMarginCall(!checked);
                  }}
                  offColor="#aaa"
                  onColor="#ff0000"
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              <div className="flex items-center justify-between">
                <span>Manual profit</span>
                <input
                  placeholder={`in ${bot.profitCurrency}`}
                  className="bg-transparent border-b-[0.5px] focus:outline-none focus:ring-0"
                  value={manualProfit}
                  onChange={(e) => setmanualProfit(e.target.value)}
                />
              </div>
              <div className="flex items-center justify-between">
                <span>Fee</span>
                <input
                  placeholder={``}
                  className="bg-transparent border-b-[0.5px] focus:outline-none focus:ring-0"
                  value={fee}
                  onChange={(e) => setFee(e.target.value)}
                />
              </div>

              <div
                onClick={() => {
                  updateBot();
                }}
                className="self-end bg-green-light px-4 py-1 rounded-md cursor-pointer"
              >
                Save changes
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center sm:text-lg mt-12 font-semibold opacity-50 font-public-sans py-8">
            Nothing is here yet!
          </div>
        )}
      </Wrapper>
    </MainWrapper>
  );
}

export default Details;
