import Fuse, { IFuseOptions } from "fuse.js";

const useFuzzy = (initialData, options) => {
  const fuse = new Fuse(initialData, options);

  const search = (value) => {
    return fuse.search(value);
  };

  return { search };
};

export default useFuzzy;
