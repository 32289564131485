import React from "react";
import {
  Route,
  Navigate,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import BotActivation from "./components/BotActivationRequests/Main";
import BotManagement from "./components/BotManagement/Main";
import UserDetails from "./components/BotManagement/User";
import PayoutCenter from "./components/PayoutCenter/Main";
import Notifications from "./components/Notifications/Main";
import Details from "./components/BotActivationRequests/Details";
import DataRoom from "./components/DataRoom";
import InvoiceCenter from "./components/InvoiceCenter/Main";
import BotInvoices from "./components/InvoiceCenter/BotInvoices";
import { ProtectedRoute } from "./ProtectedRoute";
import { useLocalStorage } from "./hooks";

function Routing() {
  const [authToken] = useLocalStorage("authToken", "");
  const Authenticated = () => {
    if (authToken) return <Navigate to="/notifications" />;
    return <Dashboard />;
  };

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Authenticated />}></Route>
        <Route
          exact
          path="/notifications"
          element={
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/bots-management"
          element={
            <ProtectedRoute>
              <BotManagement />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/bots-management/user-details"
          element={
            <ProtectedRoute>
              <UserDetails />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/bots-activation"
          element={
            <ProtectedRoute>
              <BotActivation />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/bots-activation/details"
          element={
            <ProtectedRoute>
              <Details />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/payout"
          element={
            <ProtectedRoute>
              <PayoutCenter />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/data-room"
          element={
            <ProtectedRoute>
              <DataRoom />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/invoice"
          element={
            <ProtectedRoute>
              <InvoiceCenter />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/invoice/bot-invoices"
          element={
            <ProtectedRoute>
              <BotInvoices />
            </ProtectedRoute>
          }
        ></Route>
      </Routes>
    </Router>
  );
}

export default Routing;
