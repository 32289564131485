import React, { createContext, useContext, useMemo, useState } from "react";
import { useLocalStorage } from "../hooks";

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export function AppContextProvider({ children }) {
  const [signinToken, setsigninToken] = useLocalStorage("");
  const [user, setuser] = useLocalStorage({});
  const [activeTab, setActiveTab] = useState("");

  const contextValue = useMemo(
    () => ({ signinToken, setsigninToken, activeTab, setActiveTab }),
    [signinToken, activeTab]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}
