import dayjs from "dayjs";

export const style_select_picker = {
  borderRadius: "0.375rem",
  paddingBottom: "0.6rem",
  paddingTop: "0.6rem",
  fontWeight: "normal",
  //outline: "none",
  fontSize: "14px",
  boxShadow: "none",
  color: "grey",
  width: "200px",
  textAlign: "center",
};

export const AmountSeparator = (amount, decimal) => {
  const separate = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const rounding = (Math.round(amount * 100) / 100).toFixed(decimal || 2);
  const cal = separate(rounding);
  return cal;
};

export const formatDate = (date, format) => dayjs(date).format(format); //format sample MMM DD, YYYY. h:mm a

export const maskString = (string) => {
  if (!string) return "";
  const visibleLength = 3; // Number of visible characters at the beginning and end of the string
  const maskedChars = "...";
  return `${string.slice(0, visibleLength)}${maskedChars}${string.slice(
    -visibleLength
  )}`;
};

export const btcData = {
  strategy: [
    { name: "CB002", return: "1.5%", direction: "LONG", leverage: "1.0x" },
    { name: "CB003", return: "2.25%", direction: "LONG", leverage: "1.5x" },
    { name: "CB004", return: "1.5%", direction: "LONG", leverage: "1.0x" },
    { name: "CB005", return: "2.25%", direction: "LONG", leverage: "1.5x" },
  ],
};

export const ethData = {
  strategy: [
    { name: "CB007", return: "2.0%", direction: "LONG", leverage: "1.0x" },
    { name: "CB008", return: "3.0%", direction: "LONG", leverage: "1.5x" },
    { name: "CB009", return: "2.0%", direction: "LONG", leverage: "1.0x" },
    { name: "CB010", return: "3.0%", direction: "LONG", leverage: "1.5x" },
  ],
};

export const solData = {
  strategy: [
    { name: "CB012", return: "2.0%", direction: "LONG", leverage: "1.0x" },
    { name: "CB013", return: "3.0%", direction: "LONG", leverage: "1.5x" },
    { name: "CB014", return: "2.0%", direction: "LONG", leverage: "1.0x" },
    { name: "CB015", return: "3.0%", direction: "LONG", leverage: "1.5x" },
  ],
};

export const usdtData = {
  strategy: [
    { name: "CB016", return: "1.5%", direction: "SPOT", leverage: "1.0x" },
    { name: "CB018", return: "8.0%", direction: "SPOT", leverage: "1.5x" },
  ],
};

export const BOT_ERROR_STATUS = {
  INSUFFICIENT_FUNDS: "INSUFFICIENT_FUNDS",
  BAD_API: "BAD_API",
  NO_TRADE_PERMISSION: "NO_TRADE_PERMISSION",
};

export const some_years = [
  { name: "1", id: "2020" },
  { name: "2", id: "2021" },
  { name: "3", id: "2022" },
  { name: "4", id: "2023" },
  { name: "5", id: "2024" },
];

export const all_strategies = [
  { name: "1", id: "CB001" },
  { name: "2", id: "CB002" },
  { name: "3", id: "CB003" },
  { name: "4", id: "CB004" },
  { name: "5", id: "CB005" },
  { name: "6", id: "CB006" },
  { name: "7", id: "CB007" },
  { name: "8", id: "CB008" },
  { name: "9", id: "CB009" },
  { name: "10", id: "CB010" },
  { name: "11", id: "CB011" },
  { name: "12", id: "CB012" },
];

export const months_of_year = [
  { name: "1", id: "January" },
  { name: "2", id: "February" },
  { name: "3", id: "March" },
  { name: "4", id: "April" },
  { name: "5", id: "May" },
  { name: "6", id: "June" },
  { name: "7", id: "July" },
  { name: "8", id: "August" },
  { name: "9", id: "September" },
  { name: "10", id: "October" },
  { name: "11", id: "November" },
  { name: "12", id: "December" },
];

export const strategy_direction = [
  { name: "1", id: "Long" },
  { name: "2", id: "Short" },
];
