import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Main from "./Main";
import MainWrapper from "../MainWrapper";
import Logo from "../../assets/images/logo.png";

function index() {
  return (
    <MainWrapper>
      <div className="w-full min-h-screen flex flex-col gap-10 justify-center items-center">
        <img className="rounded-full" src={Logo} alt="" />
        <Main />
      </div>
    </MainWrapper>
  );
}

export default index;
