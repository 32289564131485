import { useState } from "react";

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get value from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // Return initialValue on error
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      // Allow value to be a function to mimic useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export const useLoading = () => {
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    loadingCount: 0,
  });

  const startLoading = () => {
    setLoadingState((prevState) => ({
      isLoading: true,
      loadingCount: prevState.loadingCount + 1,
    }));
  };

  const stopLoading = () => {
    setLoadingState((prevState) => ({
      isLoading: prevState.loadingCount - 1 > 0,
      loadingCount: Math.max(prevState.loadingCount - 1, 0),
    }));
  };

  return [loadingState.isLoading, startLoading, stopLoading];
};

export default { useLoading, useLocalStorage };
