import { Navigate } from "react-router-dom";
import { useLocalStorage } from "./hooks";

export const ProtectedRoute = ({ children }) => {
  const [authToken] = useLocalStorage("authToken", "");
  if (!authToken) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};
